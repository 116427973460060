.item {
  flex: 1 0 12%;
  text-align: center;
  text-transform: uppercase;
  width: -moz-fit-content;
  width: fit-content;
}

.item a img {
  box-shadow: 0 0 10px 8px white;
  border: 6px solid black;
  border-radius: 5px;
  margin: 0 auto;
  width: 90px;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}

.item img:hover {
  box-shadow: 0 0 10px 10px white;
  cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.item p {
  margin-top: 30px;
}

.item * {
  display: block;
}

#items {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 700;
  gap: 40px;
  justify-content: space-evenly;
  padding: 60px 25px 40px 25px;
}

#item-actives {
  margin: 40px 0;
}

.item-active {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  max-width: 800px;
  width: 800px;
}

.item-build {
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
  font-weight: 700;
  text-align: center;
}

.item-build-img {
  box-shadow: 0 0 10px 10px white;
  border: 4px solid black;
  border-radius: 90px;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
  width: 80px;
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}

.item-build-img:hover {
  box-shadow: 0 0 10px 12px white;
  cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.item-build-name {
  color: #ffd966;
  font-size: 1.2rem;
  margin-top: 20px;
}

.item-build-none {
  color: rgb(225, 60, 60);
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

#item-builds {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 40px 30px;
  width: 100%;
}

#item-builds-list {  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  row-gap: 60px;
}

#item-detail {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  justify-content: space-evenly;
  margin-top: 10px;
}

.item-heading {
  color: #ffebc5;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
}

.item-heading-img {
  width: 30px;
  vertical-align: middle;
  padding: 0 25px 5px 25px;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
}

#item-img img {
  box-shadow: 0 0 8px 8px white;
  border: 6px solid black;
  border-radius: 5px;
  max-width: 100px;
  width: 90px;
  margin: 40px 0 30px 0;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
}

#item-name {
  color: #ffd966;
  font-size: 3.5rem;
  font-family: 'Shadows Into Light', sans-serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-shadow: 2px 1px 40px #ffe185;
  max-width: 800px;
  width: 800px;
  text-align: center;
}

#item-passives {
  margin: 40px 0;
}

.item-passive {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  max-width: 800px;
  width: 800px;
}

#item-price {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.7rem;
  font-weight: 700;
  gap: 30px;
  margin: 20px 0;
}

#item-price-buy {
  align-items: flex-end;
  color: #ffd966;
  display: flex;
  flex-direction: column;
  width: 100px;
}

#item-price-buy div:first-of-type {
  color: #ffebc5 !important;
  font-size: 1.6rem;
  margin-bottom: 5px;
  text-align: right;
}

#item-price-sell {
  align-items: flex-start;
  color: rgb(225, 60, 60);
  display: flex;
  flex-direction: column;
  width: 100px;
}

#item-price-sell div:first-of-type {
  color: #ffebc5 !important;  
  font-size: 1.6rem;
  margin-bottom: 5px;
  text-align: left;
}

#item-price-buy-img {
  margin-right: 4px;
  width: 25px;
}

#item-price-sell-img {
  margin-left: 4px;
  width: 25px;
}

#item-price-title {
  display: flex;
  font-weight: 700;
  gap: 20px;
  margin: 10px 0 6px 0;
}

.item-stat {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  font-weight: 600;
  gap: 5px;
  text-transform: capitalize;
}

.item-stat-dash {
  flex-basis: 4%;
  text-align: center;
}

.item-stat-mod {
  flex-basis: 48%;
  text-align: right;
}

.item-stat-value {
  flex-basis: 48%;
}

#item-stat-flex {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

#item-stats {
  margin: 40px 0;
  max-width: 90%;
  width: 90%;
}

#item-tier {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
}

#item-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 auto 15px auto;
  max-width: 700px;
  width: 700px;
  text-align: center;
}

.item-usage {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.item-usage::first-letter { 
  text-transform: capitalize;
}

#item-usage-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

#item-usages {
  margin: 40px 0;
  text-align: center;
}


@media screen and (max-width: 500px) {
  .item {
    flex: 1 0 22%;
  }

  .item a img {
    box-shadow: 0 0 8px 5px white;
    border: 4px solid black;
    width: 60px;
    margin: 0 auto;
  }

  .item p {
    margin-top: 22px;
  }

  #items {
    font-size: 0.7rem;
    column-gap: 10px;
    row-gap: 20px;
    padding: 20px 5px;
  }

  .item-active {
    font-size: 1rem;
    text-align: left;
    max-width: 370px;
    width: 370px;
  }

  #item-actives {
    margin: 20px 0;
  }

  .item-build {
    flex-basis: 30%;
  }
  
  .item-build-img {
    box-shadow: 0 0 10px 8px white;
    width: 60px;
  }

  .item-build-name {
    font-size: 0.9rem;
  }

  #item-builds {
    margin: 20px 30px;
  }

  #item-builds-list {
    column-gap: 10px;
    row-gap: 30px;
  }

  .item-heading {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .item-heading-img {
    width: 20px;
    padding: 0 10px;
  }

  #item-img img {
    box-shadow: 0 0 8px 7px white;
    border: 4px solid black;
    margin: 25px 0 25px 0;
    width: 80px;
  }

  #item-name {
    font-size: 1.9rem;
    letter-spacing: 0.7px;
    text-shadow: 2px 1px 40px #ffe185;
    width: 380px;
    max-width: 380px;
  }

  .item-passive {
    font-size: 1rem;
    text-align: left;
    max-width: 370px;
    width: 370px;
  }

  #item-passives {
    margin: 20px 0;
  }

  #item-price {
    font-size: 1.6rem;
  }

  #item-price-buy div:first-of-type {
    font-size: 1.4rem;
  }

  #item-price-sell div:first-of-type {
    font-size: 1.4rem;
  }

  .item-stat {
    font-size: 1rem;
  }

  .item-stat-dash {
    flex-basis: 6%;
  }
  
  .item-stat-mod {
    flex-basis: 47%;
  }
  
  .item-stat-value {
    flex-basis: 47%;
  }

  #item-stats {
    margin: 20px 0;
  }

  #item-title {
    font-size: 0.9rem;
    letter-spacing: 0.4px;
    margin-top: 5px;
    max-width: 350px;
    width: 350px;
  }

  .item-usage {
    font-size: 1rem;
    max-width: 400px;
  }

  #item-usages {
    margin: 20px 0;
  }
}