.App {
  /* background: rgba(24, 24, 24, 1); */
  background-image: url("../../images/bg_black_compressed_lighter.webp");
  background-blend-mode: lighten;
  border-left: 14px solid rgba(40, 40, 40, 1);
  border-right: 14px solid rgba(40, 40, 40, 1);
  color: white;
  font-family: 'Inconsolata', 'Roboto', sans-serif;
  letter-spacing: 1px;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 1200px;
}

a {
  text-decoration: none;
}

body {
  background: rgba(34, 34, 34, 1);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inconsolata', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: 700;
}

.color-blue {
  color: #48c6e4;
}

.color-brown {
  color: rgb(189, 53, 53);
}

.color-green {
  color: mediumaquamarine;
}

.color-gold {
  color: #ffd468;
}

.color-red {
  color: red;
}

.color-orange {
  color: orangered;
}

.color-yellow {
  color: rgb(255, 255, 195);
}

#contact {
  margin-top: 30px;
}

#contact a {
  color: #ffd468;
}

#contact a:hover {
  font-weight: 600;
}

#contact-body {
  font-size: 1rem;
  padding: 50px 50px;
  text-align: left;
}

#contact-email {
  padding-bottom: 50px;
}

.contact-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#contact-header {
  border-bottom: 6px solid #ffd966;
  color: #ffd966;
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
}

#contact-header div {
  padding-bottom: 10px;
}

.contact-sub-header {
  border-bottom: 3px solid #ffd966;
  color: #ffd966;
  font-size: 1.1rem;
  font-weight: bold;
  width: -moz-fit-content;
  width: fit-content;
}

.empty {
  display: none;
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
}

.fade-in-fast {
  animation: fadeIn 200ms;
  -webkit-animation: fadeIn 200ms;
}

.fade-in-slow {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flexbox-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

footer {
  /* background:rgba(24, 24, 24, 1); */
  flex-shrink: 0;
  font-size: 1rem;
  margin-top: 30px;
  padding: 2px 0 5px 0;
}

footer a {
  color: #ffd966;
}

footer a:hover {
  font-weight: 700;
  text-shadow: 6px 2px 30px #ffe185;
}

footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style-type: none;
  padding-left: 0;
}

header {
  /* background:rgba(24, 24, 24, 1); */
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 50px;
}

.header-button {
  background: #ffe185;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffe185), to(#ffd145));
  background: -moz-linear-gradient(top, #ffe185, #ffd145);
  background: linear-gradient(to bottom, #ffe185, #ffd145);
  box-shadow: 0 0 10px 4px #ffe185;
  border: 8px solid #ffe185;
  border-radius: 5px;
  color: black;
  font-family: 'Inconsolata', 'Roboto', sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 150px;
}

.header-button:hover {
  border: 8px solid #ffffff;
  box-shadow: 0 0 10px 8px white;
  cursor: pointer;
}

#header-img {
  width: 100px;
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.legal {
  padding: 10px 50px 20px 50px;
  text-align: left;
}

.legal a {
  color: #ffd468;
}

.legal a:hover {
  font-weight: 600;
}

.legal-body {
  font-size: 1rem;
  padding-bottom: 10px;
}

.legal-body-header {
  color: #ffd966;
  font-size: 1.1rem;
  font-weight: 700;
}

.legal-date {
  font-size: 0.9rem;
  padding: 10px 0 30px 0;
  text-align: center;
}

.legal-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 20px;
}

.legal-header {
  border-bottom: 4px solid #ffd966;
  color: #ffd966;
  font-size: 2.2rem;
  font-weight: 600;
  margin: 30px auto 0 auto;
  width: -moz-fit-content;
  width: fit-content;
}

.legal-sub-header {
  border-bottom: 3px solid #ffd966;
  color: #ffd966;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
  width: -moz-fit-content;
  width: fit-content;
}

main {
  display: flex;
  flex-direction: column;
}

.margin-top-10 {
  margin-top: 10px;
}

#not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#not-found-img {
  margin: 10px 0 40px 50px;
  width: 300px;
}

#not-found-quote {
  text-align: center;
}

#not-found-quote h2 {
  font-size: 2.2rem;
}

#not-found-quote h2, h4 {  
  font-family: 'Shadows Into Light', sans-serif;
}

#not-found-quote h4 {
  text-align: right !important;
}

#not-found-top {
  text-align: center;
  margin-bottom: 40px;
}

#not-found-top h1 {
  font-size: 5rem;
  font-family: 'Shadows Into Light', sans-serif;
  letter-spacing: 5px;
  margin: 0;
  text-decoration: underline;
}

#not-found-top h5 {
  font-size: 1rem;
  margin: 0;
}

#patch {
  color: rgba(60, 60, 60, 1);
  font-size: 0.9rem;
  font-weight: 700;
  padding: 4px;
  position: absolute;
}

#riot-disclaimer {
  font-size: 0.8rem;
  font-weight: 400;
  padding: 20px 150px;
}

section {
  flex: 1 0 auto;
  padding: 20px 0;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

@media screen and (max-width: 1100px) {
  .App {
    border-left: 8px solid rgba(40, 40, 40, 1);
    border-right: 8px solid rgba(40, 40, 40, 1);
  }

  footer {
    font-size: 1.2rem;
    margin-top: 20px;
    padding: 0 20px;
  }

  .header-button {
    font-size: 1.5rem;
    width: 120px;
  }

  .legal {
    padding: 10px 30px 20px 30px;
  }

  #riot-disclaimer {
    font-size: 1rem;
    padding: 20px 60px;
  }
}

@media screen and (max-width: 800px) {
  footer {
    font-size: 1rem;
    margin-top: 20px;
    padding: 0 10px;
  }

  .header-button {
    font-size: 1.1rem;
    width: 90px;
  }

  #riot-disclaimer {
    font-size: 0.8rem;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 500px) {
  .App {    
    border-left: 4px solid black;
    border-right: 4px solid black;
  }

  #contact {
    margin-top: 10px;
  }
  
  #contact-body {
    font-size: 1rem;
    padding: 30px 30px;
  }
  
  #contact-email {
    padding-bottom: 20px;
  }
  
  #contact-header {
    border-bottom: 4px solid #ffd966;
    font-size: 1.7rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .contact-sub-header {
    border-bottom: 2px solid #ffd966;
    font-size: 1.4rem;
  }
  
  footer {
    font-size: 0.9rem;
    margin-top: 25px;
  }

  footer ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }

  header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
  
  .header-button {
    border-width: 4px;
    padding: 5px 5px;
    width: 90px;
  }
  
  .header-button:hover {
    border: 4px solid #ffffff;
  }
  
  #header-img {
    width: 60px;
  }

  .legal {
    padding: 10px 20px 20px 20px;
  }

  .legal-body {
    font-size: 0.9rem;
    padding-bottom: 15px;
  }

  .legal-header {
    margin: 0 auto 0 auto;
  }
  
  #not-found-img {
    margin: 5px 0 20px 40px;
    width: 180px;
  }
  
  #not-found-top h1 {
    font-size: 3.5rem;
    margin: 0;
  }

  #not-found-quote {
    padding: 0 40px;
  }

  #not-found-quote h2 {
    font-size: 1.4rem;
    margin-top: 30px;
  }

  #not-found-quote h4 {
    font-size: 0.8rem;
  }

  #not-found-top {
    margin-bottom: 20px;
  }

  #not-found-top h5 {
    font-size: 0.8rem;
  }

  #patch {
    display: none;
  }

  #riot-disclaimer {
    font-size: 0.7rem;
    padding: 20px 30px;
  }

  section {
    padding: 0 0 20px 0;
  }
}