.champion {
  flex: 1 0 12%;
  text-align: center;
  text-transform: uppercase;
  width: -moz-fit-content;
  width: fit-content;
}

.champion a img {
  box-shadow: 0 0 10px 8px white;
  border: 6px solid black;
  border-radius: 5px;
  margin: 0 auto;
  width: 100px;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}

.champion img:hover {
  box-shadow: 0 0 10px 10px white;
  cursor: pointer;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.champion p {
  margin-top: 30px;
}

.champion * {
  display: block;
}

#champions {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 700;
  gap: 40px;
  justify-content: space-evenly;
  padding: 60px 25px 40px 25px;
}

#champion-abilities {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 30px;
  margin: 40px 30px 20px 30px;
}

#champion-abilities .champion-heading-img {
  width: 40px;
}

#champion-detail {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  justify-content: space-evenly;
  margin-top: 10px;
}

.champion-heading {
  color: #ffebc5;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
}

.champion-heading-img {
  width: 30px;
  vertical-align: middle;
  padding: 0 25px 5px 25px;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
}

#champion-img img {
  box-shadow: 0 0 10px 15px white;
  border: 6px solid black;
  border-radius: 5px;
  max-width: 300px;
  width: 300px;
  margin: 40px 0;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
}

#champion-lore {
  font-family: 'Inconsolata', sans-serif;
  font-weight: 600;
  margin: 30px 30px 40px 30px;
}

#champion-lore-story {
  padding-top: 10px;
}

#champion-name {
  color: #ffd966;
  font-size: 3.5rem;
  font-family: 'Shadows Into Light', sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 2px 1px 40px #ffe185;
}

#champion-price {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  font-weight: 700;
  gap: 20px;
  margin: 0 0 30px 0;
}

#champion-price-be {
  align-items: center;
  color: #48c6e4;
  display: flex;
}

#champion-price-rp {
  align-items: center;
  color: #ffd966;
  display: flex;
}

#champion-price-be-img {
  margin-left: 1px;
}

#champion-price-rp-img {
  margin-left: 4px;
  width: 18px;
}

.champion-role { 
  text-transform: lowercase;
}

.champion-role::first-letter { 
  text-transform: capitalize;
}
 
#champion-update, 
#champion-release {
  width: 200px;
}

#champion-roles div, 
#champion-update div, 
#champion-release div {
  padding-bottom: 3px;
}

#champion-roles-release {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  gap: 150px;
  margin: 35px 0;
  text-align: center;
}

.champion-separator {
  border-top: 8px solid rgba(35, 35, 35, 1);
  border-radius: 5px;
  margin: 0 auto;
  width: 80%;
}

.champion-skin {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  font-weight: 700;
  gap: 8px;
}

.champion-skin-img {
  box-shadow: 0 0 10px 10px white;
  border: 4px solid black;
  border-radius: 90px;
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
  width: 160px;
}

.champion-skin-name {
  color: #ffebc5;
  font-size: 1.2rem;
  margin-top: 12px;
  text-align: center;
}

.champion-skin-price {
  align-items: center;
  color: #ffd966;
  display: flex;
  font-size: 1.2rem;
  gap: 5px;
}

#champion-skins {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 60px 30px;
  width: 100%;
}

#champion-skins-list {  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;
  row-gap: 60px;
}

.champion-spell div {
  padding: 5px 0;
}

.champion-spell-description {
  margin: 30px 0;
}

.champion-spell-description-detail {
  text-transform: capitalize;
}

.champion-spell-key {
  font-size: 1.5rem;
}

.champion-spell-name {
  color: #ffd966;
  font-size: 1.8rem;
  font-weight: 700;
}

#champion-spells-title {
  margin-bottom: 30px;
}

.champion-spell-img {
  box-shadow: 0 0 10px 6px white;
  border: 8px solid rgb(0, 0, 0, 1);
  border-radius: 90px;
  width: 70px;
  vertical-align: middle;
  -webkit-filter: contrast(130%);
  filter: contrast(130%);
}

.champion-spells-row-top {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.champion-spells-row-top div {
  flex-basis: 33%;
}

.champion-spells-row {
  align-content: stretch;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  margin: 10px 0;
}

.champion-spells-row div {
  flex-basis: 33%;
}

.champion-spells-row span {
  display: block;
  text-transform: lowercase;
}

.champion-spells-row span {
  text-transform: capitalize;
}

.champion-spells-row-key {
  color: #ffebc5;
  font-weight: 700;
  margin-bottom: 5px;
}

#champion-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: -5px;
}

#champion-tips {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  gap: 100px;
  margin: 40px 30px;
  min-width: 90%;
}

.champion-tip {
  flex-basis: 50%;
}

.champion-tip-empty {
  text-align: center;
}

@media screen and (max-width: 1100px) {
  #champion-abilities {
    margin: 40px 20px;
  }

  #champion-detail {    
    font-size: 1rem;
  }

  .champion-spell-key {
    font-size: 1.2rem;
  }

  #champion-lore {
    margin: 40px 20px;
  }
  
  .champion-mini-title {
    font-size: 1.6rem;
  }

  #champion-tips {
    gap: 50px;
    margin: 20px 20px;
  }

  .champion-spell-name {
    font-size: 1.8rem;
  }  

  .champion-spell-img {
    width: 80px;
  }
}

@media screen and (max-width: 800px) {
  #champion-abilities {
    margin: 40px 20px;
  }

  #champion-abilities .champion-heading-img {
    width: 30px;
  }

  #champion-detail {
    font-size: 1rem;
  }

  .champion-heading {
    font-size: 1.3rem;
  }

  .champion-heading-img {
    width: 25px;
  }

  .champion-spell-key {
    font-size: 1.1rem;
  }

  #champion-lore {
    margin: 40px 20px;
  }
  
  .champion-mini-title {
    font-size: 1.6rem;
  }

  #champion-tips {
    gap: 50px;
    margin: 20px 20px;
  }

  #champion-roles-release {
    gap: 75px;
    margin: 25px 0;
  }

  .champion-spell-name {
    font-size: 1.8rem;
  }

  .champion-skin {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-basis: 33.3%;
    font-weight: 700;
    gap: 8px;
  }
  
  .champion-skin-img {
    width: 140px;
  }

  #champion-skins {
    margin: 40px 30px;
  }

  .champion-spell-img {
    width: 70px;
  }
}

@media screen and (max-width: 500px) {
  .champion {
    flex: 1 0 22%;
  }

  .champion a img {
    box-shadow: 0 0 8px 5px white;
    border: 4px solid black;
    width: 60px;
    margin: 0 auto;
  }

  .champion p {
    margin-top: 22px;
  }

  #champion-abilities {
    margin: 50px 20px 20px 20px;
  }

  #champion-abilities .champion-heading-img {
    width: 25px;
  }

  .champion-heading {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .champion-heading-img {
    width: 20px;
    padding: 0 10px;
  }

  #champion-img img {
    box-shadow: 0 0 8px 10px white;
    border: 4px solid black;
    max-width: 180px;
  }
  
  .champion img:hover {
    border: 2px solid #ffc61b;
    cursor: pointer;
  }

  #champions {
    font-size: 0.7rem;
    column-gap: 10px;
    row-gap: 20px;
    padding: 20px 5px;
  }

  #champion-lore {
    margin-top: 30px;
  }

  #champion-name {
    font-size: 2.4rem;
    letter-spacing: 0.7px;
    margin-top: 1px;
  }

  #champion-roles-release {
    gap: 35px;
  }

  .champion-separator {
    border-top: 4px solid rgba(35, 35, 35, 1);
    width: 70%;
  }

  .champion-skin {
    flex-basis: 33%;
    font-size: 0.9rem;
    gap: 5px;
  }
  
  .champion-skin-img {
    box-shadow: 0 0 8px 6px white;
    border: 4px solid black;
    width: 70px;
  }
  
  .champion-skin-name {
    font-size: 0.9rem;
  }
  
  .champion-skin-price {
    font-size: 0.9rem;
  }

  .champion-skin-price-img {
    width: 15px;
  }
  
  #champion-skins {
    margin: 40px 0;
    width: 100%;
  }
  
  #champion-skins-list {  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
    row-gap: 50px;
  }

  .champion-spell-description {
    margin: 15px 0;
  }

  .champion-spell-key {
    font-size: 0.9rem;
  }

  .champion-spell-name {
    font-size: 1.1rem;
    font-weight: 700;
  }
  
  .champion-spell-img {
    box-shadow: 0 0 6px 4px white;
    width: 40px;
  }

  .champion-spells-row {
    gap: 10px;
  }

  .champion-spells-row-top {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #champion-tips {
    flex-direction: column;
    gap: 50px;
    margin: 40px 20px;
    width: 85%;
  }

  #champion-title {
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 5px;
  }

  #champion-update, 
  #champion-release {
    width: 100px;
  }
}